  import React, { useState, useEffect } from 'react'
  import logo from '../images/toonflix.webp'
  import axios from 'axios'
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import './Subscription.css'
  import gif from '../images/gif.gif'

  const Subscription = () => {
    const [number, setNumber] = useState('')
    const [zoomEffect, setZoomEffect] = useState('');

    const [showSuccessGif, setShowSuccessGif] = useState(false); // New state for tracking GIF display

    useEffect(() => {
      setZoomEffect('zoom-out');
    }, []);


    const url ='http://kentelkom.toon-flix.com/subscription'
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {

        const data = {
          msisdn: number
        }

        const response = await axios.post(url, data);
        console.log("data :", response.data);

        if (response.data == 'Success'){
          toast.success("User subscribed successfully!");
        } 
        if (response.data.code == '10033') {
          // setShowSuccessGif(true); 
          // setTimeout(() => setShowSuccessGif(false), 5000); 
          toast.error("Invalid Msisdn");
        }
        if (response.data.code == '10022') {
          
          toast.error("User exists !");
        }
      } catch (error) {
        console.error('Error making post request:', error);
      }
    };

    if (showSuccessGif) {
      return (
        <div className="fixed inset-0 bg-gray-200 flex justify-center items-center z-50">
          <img src={gif} alt="Success" />
        </div>
      );
    }


    return (
      <div className='bg-[#FF9700] h-screen '>

      <div className={`zoom-container ${zoomEffect}`}>
        <div className='flex justify-center py-[100px] px-6'>
       
          <div className="w-full max-w-sm p-4 bg-white border  shadow-lg shadow-[#FF4003]
          border-gray-300 rounded-lg  sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <form className="space-y-6" action="#" onSubmit={handleSubmit}>
              <a >
                <img className="rounded-t-lg bounce-image" src={logo} alt="" />
              </a>
              <h5 className="text-xl  text-gray-900 font-bold dark:text-white text-center font-sans">Subscribe to Toonflix</h5>
              <div>
                <label for="number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your number</label>
                <input type="number" name="number" id="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
              dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="88*******" required />
              </div>

              <button type="submit" className="w-full text-white bg-[#007CA3] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Subscribe</button>

            </form>
          </div>

      
        </div>


        <ToastContainer />
      </div>
      </div>
    )
  }

  export default Subscription
