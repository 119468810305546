import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";


const VideoCards = () => {
  const storeFilteredVideos = useSelector((state) => state.category.videos);
   console.log("fijhg" ,storeFilteredVideos)
  const navigate=useNavigate()
  
  const fetchContinuevideos=async( videoid,name,image,vurl,)=>{
    const ani=Cookies.get('number')
    console.log("data ", name, videoid,image,vurl)
      try {
  
        const data={
          ani:ani,
          videoid:videoid,
          imageurl:image,
          title:name,
          vurl:vurl
         
        }
      console.log("data",data)
        // const res = await axios.post(`/api/little/continue-watching`,data);
        const res = await axios.post(`/api/little/continue-watching`,data);
        console.log("res", res.data);
       
         navigate(`/VideoItem/${videoid}`)
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

  return (
    <div className="container mx-auto max-w-[1140px] px-4 py-1">
  <div  className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-0 mb-2">
          {storeFilteredVideos.map((data) => (
            <div key={data.id} className="text-white cursor-pointer">
            
                <div className="relative w-full overflow-hidden rounded-xl" onClick={()=>fetchContinuevideos(data.id,data.name,data.imgurl,data.vurl,data.videoId )}>
                  <img
                    src={data.imgurl}
                    alt={data.name}
                    width={500}
                    height={300}
                    layout="responsive"
                    className="w-full h-[200px] object-cover rounded-t-lg relative backdrop-blur-3xl"
                  />
                  <div className="absolute bottom-0 left-0 w-full text-center bg-black bg-opacity-80 p-1">
                    <p className="text-white ">{data.name}</p>
                  </div>
                </div>
             
            </div>
            

             
          ))}
        </div>
    </div>
 
  );
};

export default VideoCards;
