import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./Components/Home"
import VideoItem from './Pages/VideoItem';
import Header from './Pages/Header';
import ScrollToTop from './Pages/ScrollToTop';
import Subscription from './Pages/Subscription'
function App() {
  // useEffect(() => {
  //   const isLoggedIn = Cookies.get('loginStatus');

  //   if (!isLoggedIn === 'false') {
  //      window.location.href = '/'; 
  //   }
  // }, []);

  return (
    <>
      <div className='bg-slate-200'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<React.Fragment><ScrollToTop /><Home /></React.Fragment>} />
            <Route path='/subscription' element={<Subscription/>}/>
            <Route path='/VideoItem/:id' element={<React.Fragment><ScrollToTop /><VideoItem /></React.Fragment>} />
            <Route path='/Header' element={<React.Fragment><ScrollToTop /><Header /></React.Fragment>} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
