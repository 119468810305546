// Import necessary libraries and components
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  setCategories,
  setSelectedCategory,
  setVideos,
} from "../slices/categorySlice";
import Navbar from "../Pages/Navbar";
import VideoCards from "../Pages/VideoCards";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import ContinueWatching from "../Pages/ContinueWatching";

const Home = () => {
  const dispatch = useDispatch();
  const storeCategory = useSelector((state) => state.category);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/little");
        // const response = await axios.get("/api/little");
        const { categories, videos } = response.data;

        dispatch(setCategories(categories));
        dispatch(setVideos(videos));
        dispatch(setSelectedCategory("All"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="text-sm  ">
        <Navbar />
        <>
          <Header categories={storeCategory} />
          <ContinueWatching />
          <VideoCards categories={storeCategory} />
        </>
      </div>
      <Footer className="" />
    </>
  );
};

export default Home;
